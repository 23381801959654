import axios from 'axios';

export default class Form{
  constructor(e){
    this.element = e;
    this.submit_button = this.element.querySelector('.echo_form_submit');
    this.messages = this.element.querySelector('.echo_form_messages');
    this.element.addEventListener('submit', (e) => this.submitForm(e));
    this.element.querySelectorAll('.radio.other input[type="text"], .checkbox.other input[type="text"]').forEach((e) => {
      e.addEventListener('input', this.selectOther);
      e.addEventListener('focus', (e) => this.prefillOtherInput(e));
    });
    this.element.querySelectorAll('.radio.other input[type="radio"]').forEach((e) => {
      e.closest('.form_field').addEventListener('change', this.syncOtherInput);
    });
    this.element.addEventListener('change', (e) => this.checkRequired(e));
    this.element.addEventListener('input', (e) => this.checkRequired(e));
  }
  async submitForm(e){
    e.preventDefault();
    e.stopPropagation();

    let first_missing = this.checkRequired();
     
    if (first_missing){
      first_missing.scrollIntoView();
      return;
    }

    this.disableBlankOthers();
    try{
      this.submit_button.disabled = true;
      const response = await axios.post(this.element.getAttribute('action'), new FormData(this.element));
      this.clear();
      this.showMessage(response.data.message);
      this.postSubmit();
    } catch(error){
      this.postSubmit();
    }
  }

  postSubmit(){
    this.submit_button.disabled = false;
    this.enableOthers();
  }
  
  disableBlankOthers(){
    this.element.querySelectorAll('.radio.other input[type="text"], .checkbox.other input[type="text"]').forEach((e) => {
      if (e.value.trim().length <= 0){
        e.disabled = true;
      }
    });
  }
  
  enableOthers(){
    this.element.querySelectorAll('.radio.other input[type="text"], .checkbox.other input[type="text"]').forEach((e) => {
      e.disabled = false;
    });
  }

  clear(){
    this.element.querySelectorAll('input[type="text"], input[type="email"]').forEach((e) => e.value = '');
    this.element.querySelectorAll('input[type="radio"], input[type="checkbox"]').forEach((e) => e.checked = false);
  }
  checkRequired(){
    this.element.querySelectorAll('.form_field.required').forEach((e) => this.checkRequiredField(e));
    return this.missingRequired();
  }

  missingRequired(){
    let first_missing = this.element.querySelector('.required.missing');
    this.clearMessage();
    if (first_missing){
      this.showMessage('Please enter all required information');
      return first_missing;
    } else {
      return false;
    }
  }
  
  clearMessage(){
    this.messages.innerHTML = '';
    this.messages.classList.remove('show');
  }

  showMessage(message){
    this.messages.innerHTML = message;
    this.messages.classList.add('show');
  }

  selectOther(){
    let form_option = this.closest('.form_option');
    let other = form_option.querySelector('input[type="radio"], input[type="checkbox"]');
  
    if (this.value.trim().length > 0){ 
      other.checked = true;
      this.closest('.form_field').querySelectorAll('.form_option:not(.other) input[type="radio"]').forEach((r) => {
        r.checked = false;
      });
    } else {
      other.checked = false;
    }
  }

  prefillOtherInput(e){
    if (e.target.user_value){
      e.target.value = e.target.user_value;
      this.selectOther.call(e.target);
    }
  }
  
  syncOtherInput(e){
    if (e.target.getAttribute('type') == 'text') return;
    
    if (e.target.checked){
      let text_input = this.querySelector('.other input[type="text"]');
      if ((!text_input.user_value || (text_input.user_value && text_input.user_value.length == 0)) && text_input.value.trim().length > 0){
        text_input.user_value = text_input.value;
      }
      text_input.value = '';
      this.querySelector('.other input[type="radio"]').checked = false;
    }
  }
  
  checkRequiredField(e){
    switch(e.dataset.type){
      case 'text_input':
        this.checkMissingTextInput(e);
      break;
      case 'radio_group':
        this.checkMissingGroup(e);
      break;
      case 'checkbox_group':
        this.checkMissingCheckbox(e);
      break;
      case 'appointment':
        this.checkMissingAppointment(e);
      break;
    }
  }
  checkMissingTextInput(e){
    if (e.querySelector('input[type="text"]').value.trim().length == 0){
      e.classList.add('missing');
    } else {
      e.classList.remove('missing');
    }
  }

  checkMissingAppointment(e){
    if (e.querySelectorAll('input[type="checkbox"]:checked').length == 0){
      e.classList.add('missing');
    } else {
      e.classList.remove('missing');
    } 
  }

  checkMissingGroup(e){
    let other = e.querySelector('.form_option.other input[type="text"]');
    if (other && other.value.trim().length > 0){
      e.classList.remove('missing');
      return;
    }

    if (e.querySelectorAll('input[type="radio"]:checked').length == 0){
      e.classList.add('missing');
    } else {
      e.classList.remove('missing');
    }
  }

  checkMissingCheckbox(e){
    let other = e.querySelector('.form_option.other input[type="text"]');
    if (other && other.value.trim().length > 0){
      e.classList.remove('missing');
      return;
    }

    if (e.querySelectorAll('input[type="checkbox"]:checked').length == 0){
      e.classList.add('missing');
    } else {
      e.classList.remove('missing');
    } 
  }

  static init(){
    document.querySelectorAll('.echo_form').forEach((form) => new Form(form));
  }
}



