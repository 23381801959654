import axios from 'axios';

export function init(){
  document.querySelectorAll('#datasets input[type="checkbox"]').forEach((e) => {
    e.addEventListener('change', updateDownloadUrl);
  });

  var portal_form = document.querySelector('#data_portal_container form');
  if (portal_form){
    var portal_select = document.querySelector('#data_portal_container select');
    portal_form.addEventListener('submit', openPortal);
    portal_select.addEventListener('change', checkPortalButton);
  } 
}

function checkPortalButton(){
  if (this.value * 1 > 0){
    document.querySelector('#data_portal_container button').disabled = false;
  } else {
    document.querySelector('#data_portal_container button').disabled = true;
  }
}


function openPortal(e){
  e.stopPropagation();
  e.preventDefault();
  var select = this.querySelector('select');
  var frame = this.closest('#data_portal_container');

  if (select.value * 1 > 0){
    axios.post('/portal_access/' + select.value).then((response) => {
      frame.removeChild(frame.querySelector('form'));
    });
  } 
}

function updateDownloadUrl(){
  var ids = [];
  document.querySelectorAll('#datasets input[type="checkbox"]').forEach((e) => {
    if (e.checked){
      ids.push(e.dataset.id);
    }
  }); 
  
  if (ids.length > 0){
    document.querySelector('#datasets').classList.add('downloadable');
  } else {
    document.querySelector('#datasets').classList.remove('downloadable');
  }

  document.getElementById('dataset_download_link').href = '/dataset_download/' + ids.join(',');
}
