import axios from 'axios';

var close_clicked = false;
var load_button, close_popup, last_focus;

export function init(){
  axios.defaults.headers.common['X-CSRF-Token'] = document.head.querySelector("[name='csrf-token']").content;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers.common['Accept'] = 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript';
  
  document.querySelectorAll('.fullres_button').forEach((e) =>{
    e.addEventListener('click', loadHandler);
  });

  close_popup = document.getElementById('close_popup');
  if (close_popup){
    close_popup.addEventListener('click', closePopup);
    close_popup.addEventListener('blur', focusLoadAll);
  }
  var reset_button = document.querySelector('.reset_button');
  if (reset_button){
    reset_button.addEventListener('click', resetImageLoading);
  }

  load_button = document.getElementById('load_button');
  if (load_button){
    load_button.addEventListener('blur', focusClose);
  }
  

  document.querySelectorAll('.load_button').forEach((e) => {
    e.addEventListener('click', loadAllImages);
  });
}

function focusLoadAll(){
  load_button.focus();
}

function focusClose(){
  close_popup.focus();
}

function loadHandler(event){
  event.preventDefault();
  event.stopPropagation();
  if (this.closest('.image_container').classList.contains('loaded')){
    loadLowRes.call(event.target.closest('.fullres_button'));
  } else{
    loadFullRes.call(event.target.closest('.fullres_button'));
  }
}

function loadFullRes(event){
  if (event){
    last_focus = event.target.closest('a');
    event.preventDefault();
    event.stopPropagation();
  }
  var img = this.previousElementSibling;
  var figure = this.closest('.image_container');
  img.setAttribute('src', img.dataset.fullres);
  figure.classList.add('loaded');
  addImageToSession(img);
}

function loadLowRes(event){
  if (event){
    last_focus = event.target.closest('a');
    event.preventDefault();
    event.stopPropagation();
  }
  var img = this.previousElementSibling;
  var figure = this.closest('.image_container');
  img.setAttribute('src', img.dataset.lowres);
  figure.classList.remove('loaded'); 
  removeImageFromSession(img);
}

function addImageToSession(img){
  axios.post('/add_image_to_session', {
    digest: img.dataset.digest
  })
  .then(function(response) {
    if (response.data.prompt){
      if (!close_clicked){
        showPrompt();
      }
    }
  })
  .catch(function(error) {
    console.log(error);
  });
}

function removeImageFromSession(img){
   axios.post('/remove_image_from_session', {
    digest: img.dataset.digest
  })
}

function loadAllImages(e){
  axios.post('/load_all_images')
  .then(function(response) {
    if (response.data.load){
      document.querySelectorAll('.fullres_button').forEach((e) =>{
        loadFullRes.call(e);
      });
      closePopup();
      document.body.classList.add('images_loaded');
    }
    if (e.target.getAttribute('id') == 'load_image' && last_focus){
      setTimeout(last_focus.focus(), 20);
    }
  })
  .catch(function(error) {
    console.log(error);
  }); 
}

function resetImageLoading(){
  axios.post('/reset_image_loading')
  .then(function(response) {
    if (response.data.reset){
      document.querySelectorAll('.fullres_button').forEach((e) =>{
        loadLowRes.call(e);
      });
      document.body.classList.remove('images_loaded');
    }
  })
  .catch(function(error) {
    console.log(error);
  }); 
}

function showPrompt(){
  document.body.classList.add('show_prompt');
  document.getElementById('close_popup').focus();
}

function closePopup(){
  document.body.classList.remove('show_prompt');
  close_clicked = true;
  if (last_focus){
    setTimeout(last_focus.focus(), 20);
  }
}