var one_open = false;

export function init(){
  var total_size = 0;

  if (document.body.classList.contains('fellowship_internal_page')){
    console.log('hid');
    document.querySelectorAll('.toggle_button').forEach((e) => {
      e.addEventListener('click', toggleFellowshipInfo)
    });
  } else{
    document.querySelectorAll('.toggle_button').forEach((e) => {
      e.addEventListener('click', toggleContainer)
    });
  }
}

function toggleFellowshipInfo(e){
  var target = document.getElementById(this.dataset.id);
  if (target.classList.contains('open')){
    one_open = false;
    target.classList.remove('open')
  } else{
    if (one_open){
      document.querySelectorAll('.info_tab_content.open').forEach((e)=>{
        e.classList.remove('open')
      });
    }
    target.classList.add('open')
    one_open = true;
  }
}


function toggleContainer(e){
  this.closest('.toggle_container').classList.toggle('open');
  if (this.innerHTML == 'View Description'){
    this.innerHTML = 'Close Description';
  } else{
    this.innerHTML = 'View Description';
  }
}