var last_jump = {};
export default function init(){
  document.querySelectorAll('.footnote').forEach((e) => e.addEventListener('click', goToFootnote));
  document.querySelectorAll('.footnote_marker').forEach((e) => e.addEventListener('click', backToFootnote));
}

function goToFootnote(e){
  var element = document.getElementById('footnote_' + this.dataset.value);
  last_jump[this.dataset.value] = this;
  element.scrollIntoView({block: "center"});
}

function backToFootnote(e){
  if (last_jump[this.dataset.value]){
    last_jump[this.dataset.value].scrollIntoView({block: 'center'});
  } else {
    document.querySelector('.footnote[data-value="' + this.dataset.value + '"]').scrollIntoView({block: 'center'});
  }
}