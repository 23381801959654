var page_classes;
var filters;
var base_url;
var window_width;


export function init(){
  if (!(document.body.classList.contains('works') || document.body.classList.contains('news'))){
    return;
  } 
  filters = [];
  page_classes = document.body.classList.value;
  if (document.body.classList.contains('works')){
    base_url = '/our_work'
  }else{
    base_url = '/news'
  }
  window_width = window.innerWidth;
  window.addEventListener('resize', resizeHandler);

  var clear_filters = document.getElementById('clear_filters');
  var view_filters = document.getElementById('view_filters');
  if (clear_filters){
    clear_filters.addEventListener('click', resetFilters);
  }
  if (view_filters){
    view_filters.addEventListener('click', showFilters);
    applyFilters();
  }
  document.querySelectorAll('.filter').forEach((e) => {
    e.addEventListener('click', handleFilterClick);
  });
}

function resizeHandler(){
  window_width = window.innerWidth;
}

function handleFilterClick(e){
  var filter_select = e.target.closest('.filter');
  if (filter_select.checked){
    filters.push(filter_select.dataset.filter);
    window.history.pushState('', '', '?params=' + filters);
    applyFilters();
  }else{
    e.target.closest('.filter').classList.remove('visible');
    filters = filters.filter(value => value != filter_select.dataset.filter);
    if (filters.length < 1){
      resetFilters();
    }else {
      window.history.pushState('', '', '?params=' + filters);
      applyFilters();
    }
  }
}

function applyFilters(){
  document.querySelectorAll('.filterable_link').forEach((e)=>{
    e.classList.remove('visible');
  })
  if (window.location.search != ''){
    var params = new URLSearchParams(window.location.search);
    document.body.classList.add('filtering');
    params.get('params').split(',').forEach((e)=>{
      document.querySelectorAll('.' + e).forEach((project)=>{
        project.classList.add('visible');
      })
    });
  }

  if (document.body.classList.contains('filtering')){
    document.getElementById('filter_count').innerHTML = document.querySelectorAll('.link_with_content.visible').length;
  }
}

function showFilters(){
  document.body.classList.add('show_filters');
  if (window_width > 1100){
    var height = document.querySelector('.page_heading').offsetTop + document.querySelector('.page_heading').offsetHeight + document.querySelector('.page_heading').offsetHeight;
  } else{
    console.log('hi');
    var height = document.querySelector('#mobile_logo').offsetHeight + document.querySelector('.page_heading').offsetHeight;
  }
  window.scrollTo(0, height);
}

function resetFilters(){
  filters = [];
  window.scrollTo(0,0);
  window.history.pushState('', '', base_url);
  document.body.classList = page_classes;
  document.querySelectorAll('.visible').forEach((e)=>{
    e.classList.remove('visible');
  })
  document.querySelectorAll('.filter').forEach((e)=>{
    e.checked = false;
  })
  document.getElementById('filter_count').innerHTML = document.querySelectorAll('.link_with_content').length;
}