import * as Analytics from '../../../vendor/gray_matter/base/app/assets/javascript/front_end/analytics.js';
import axios from 'axios';
import * as ToggleText from './site/toggle_text.js'
import * as LoadImages from './site/load_images.js'
import * as Filters from './site/filters.js'
import * as DataPortal from './site/data_portal.js'
import * as MobileNav from './site/mobile_nav.js'
import Form from './site/form.js';
import Footnotes from './site/footnotes.js'

var newsletter;

function init(){
  window.site = {};
  document.addEventListener('turbolinks:load', pageInit);
  document.addEventListener('turbolinks:before-cache', cleanup);
}

function pageInit (){
  LoadImages.init();
  ToggleText.init();
  DataPortal.init();
  Filters.init();
  MobileNav.init();
  Form.init();
  newsletter = document.getElementById('newsletter_signup');
  if (newsletter){
    newsletter.addEventListener('submit', newsletterSignup);
  }

  if (document.body.classList.contains('news_item')){
    Footnotes();
  }
}

function cleanup(){
  document.body.classList.remove('nav_open');
}

document.addEventListener("DOMContentLoaded", function(){
  init();
});

async function newsletterSignup(e){
  e.preventDefault();
  e.stopPropagation();
  try{
    const response = await axios.post(this.action, new FormData(this));
    document.getElementById('newsletter_signup').innerHTML = response.data.message;
  } catch(error){
    document.getElementById('newsletter_signup_message').innerHTML = error.response.data.message;
  }
}