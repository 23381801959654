import axios from 'axios'
var initial_referrer = true;

function init(){
  document.addEventListener('turbolinks:load', log);
  axios.defaults.headers.common['X-CSRF-Token'] = document.head.querySelector("[name='csrf-token']").content;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  axios.defaults.headers.common['Accept'] = 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript';
}

function log(){
  if (navigator.userAgent.toLowerCase().match(/(craw|bot|fetch|spider|collection|checker|charlotte|analyzer|grab|system)/)){
    return;
  }

  if (initial_referrer){
    axios.post('/gray_matter/analytics/log/', { path: location.pathname, referrer: document.referrer });
    initial_referrer = false;
  } else {
    axios.post('/gray_matter/analytics/log/', { path: location.pathname });
  }
}

document.addEventListener("DOMContentLoaded", function(){
  init();
});
